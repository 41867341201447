<template>
  <div class="landlord-apartment-config">
    <app-header v-if="title" :title="title"></app-header>
    <el-form :inline="true" ref="form" :model="form" label-width="80px">
      <el-form-item label="选择房源">
        <el-select
          v-model="form.apartmentId"
          placeholder="选择房源"
          style="display: inline-block"
          @change="selectApartment()"
        >
          <el-option
            v-for="item in apartmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <span style="margin-left: 10px; color: #606266"
          >共<span style="font-size: 18px; padding: 0 6px; font-weight: bold">{{
            builds
          }}</span
          >栋</span
        >
      </el-form-item>
      
      <el-table :data="buildsList" class="build-table" height="600px" style="width: 100%;">
        <el-table-column prop="buildName" label="楼栋名称">
          <template slot-scope="scope">
            <el-input
              style="max-width: 180px"
              placeholder="请输入楼栋名称"
              v-model="scope.row.buildName"
              disabled
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="buildType" label="楼栋属性">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.buildType"
              placeholder="请选择"
              style="display: inline-block"
              @change="selectBuildType(scope.$index, scope.row.buildType)"
            >
              <el-option
                v-for="item in buildTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="tempAuth" label="用户临时授权">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.tempAuth"
              placeholder="请选择"
              style="display: inline-block"
            >
              <el-option
                v-for="item in tempAuthList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="peopleLimit" label="单个房间入住人数上限">
          <template slot-scope="scope">
            <el-input-number
              type="number"
              v-model="scope.row.peopleLimit"
              :min="1"
              :max="100"
              :step="1"
              :step-strictly="true"
              placeholder="请填写"
              clearable
            >
            </el-input-number>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <div  class="footer-box">
        <el-button type="primary" @click="updateConfig">更新配置</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "房源配置",
      buildTypeList: [
        {
          value: 1,
          label: "宿舍/公寓",
        },
        {
          value: 2,
          label: "教学楼",
        },
        {
          value: 3,
          label: "办公楼",
        },
        {
          value: 4,
          label: "体育馆",
        },
        {
          value: 5,
          label: "综合楼",
        },
        {
          value: 6,
          label: "图书馆",
        },
        {
          value: 7,
          label: "报告厅",
        },
        {
          value: 8,
          label: "医务室",
        },
        {
          value: 9,
          label: "食堂/餐厅",
        },
      ],
      tempAuthList: [
        {
          value: true,
          label: "开启",
        },
        {
          value:false,
          label: "关闭",
        },
      ],
      apartmentList: [],
      form: {
        apartmentId: "",
      },
      total: 0,
      currentPage: 1,
      builds: 0,
      buildsList: [],
    };
  },
  activated() {
    console.log("房源配置 activated");
    this.currentPage = 1;
    this.getApartmentList();
  },
  methods: {
    async getApartmentList() {
      this.apartmentList = [];
      this.form.apartmentId = '';
      let dto = {
        landlordId: this.cache.getLS('userInfo').userId,
      }
      this.post('/landlord-service/apartment/query-by-landlord',dto,{
        isUseResponse: true,
      }).then(res => {
        if(res.data.code == 0) {
          this.apartmentList = res.data.data;
          this.form.apartmentId = this.apartmentList[0].id;
          this.getBuildsList(this.form.apartmentId);
        }else {
          this.$message.error(res.data.msg);
        }
      })
    },
    selectApartment() {
      this.currentPage = 1;
      this.getBuildsList(this.form.apartmentId);

    },
    getBuildsList(id) {
        if(!id) {
          return;
        }
        let dto = {
          apartmentId: id,
          current: this.currentPage,
          pageSize: 10,
        }
        this.post('/landlord-service/v1/house/apartment-config/query', dto,{
          isUseResponse: true,
        }).then(res => {
          if(res.data.code == 0) {
            this.buildsList = [];
            let list = res.data.data.data || [];
            list.map(item => {
              if(item.vjson) {
                let config = JSON.parse(item.vjson);
                item.buildType = config.buildType || '';
                item.tempAuth = config.tempAuth;
                item.peopleLimit = config.peopleLimit || 100;
              }else {
                item.buildType = '';
                item.tempAuth = true;
                item.peopleLimit = 100;
              }
              let configReq = {
                buildCode: item.buildCode,
                buildName: item.buildName,
                buildType: item.buildType,
                tempAuth: item.tempAuth,
                peopleLimit: item.peopleLimit,
              }
              this.buildsList.push(configReq);
            })
            this.builds = res.data.data.total;
            this.total = this.builds;
          }else {
            this.buildsList = [];
            this.builds = 0;
            this.$message.error(res.data.msg);
          }
        })
      },

    updateConfig() {
      let configReqList = [];
      if(this.buildsList.length > 0) {
        this.buildsList.forEach(item => {
          let configReq = {
            buildCode: item.buildCode,
            buildName: item.buildName,
            buildType: item.buildType,
            tempAuth: item.tempAuth,
            peopleLimit: item.peopleLimit,
          }
          configReqList.push(configReq);
        })
      }
      let dto = {
        apartmentId: this.form.apartmentId,
        configReqList: configReqList 
      }
      this.post('/landlord-service/v1/house/apartment-config/add',dto,{
        isUseResponse: true,
      }).then(res => {
        if(res.data.code == 0) {
          this.$message.success("更新成功");
        }else {
          this.$message.error(res.data.msg);
        }
      })
    },
    selectBuildType(idx, val){
      // 属性为宿舍/公寓时，默认关闭临时授权
      if(val == 1) {
        this.buildsList[idx].tempAuth = false;
      }else {
        this.buildsList[idx].tempAuth = true;
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val || 1;
      this.getBuildsList(this.form.apartmentId);
    },
  },
};
</script>
<style lang="scss" scope>
.landlord-apartment-config {
  color: #333;
  font-size: 14px;
}
.build-table {
  width: 100%
}
.footer-box {
  text-align: center;
}
</style>
